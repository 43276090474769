<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
        <h4 class="">Orders/Create</h4>
        <div>
          <router-link :to="{...previousRoute}">
            <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>
      <div>
        <div class="content-body">
          <section class="page-user-profile">
            <div class="card">
              <div class="card-body">
                <div class="" v-show="stepOne">
                  <div class="">
                    <div class="text-center mb-2">
                      <h3 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Customer Type Choose</span>
                      </h3>
                    </div>
                    <h5 class="text-center mb-2 mb-0 pb-0"><small class="text-primary">Please make a selection</small>
                    </h5>
                    <div class="d-flex justify-content-center">
                      <div class="mr-1 d-flex " v-if="can('customer-view-any')">
                        <input type="radio" value="existing" name="customerType"
                               style="opacity: 0; visibility: hidden; display: none;" v-model="customerType"
                               id="existing">
                        <label for="existing"
                               class="text-white service-type-container d-flex align-items-center justify-content-center"
                               :class="[customerType === 'existing' ? 'bg-primary text-white' : 'bg-white text-dark']"
                               :style="[customerType === 'existing' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                          <span class="mr-1 d-none d-md-block"><i class='bx bx-user-minus'></i></span>
                          <span class="mb-0 font-weight-bold service-type">Existing</span>
                        </label>
                      </div>
                      <div class="ml-1" v-if="can('customer-create')">
                        <input type="radio" value="new" name="customerType"
                               style="opacity: 0; visibility: hidden;display: none;" @click="createNewCustomer"
                               v-model="customerType" id="new">
                        <label for="new"
                               class="text-white service-type-container d-flex align-items-center justify-content-center"
                               :class="[customerType === 'new' ? 'bg-primary text-white' : 'bg-white text-dark']"
                               :style="[customerType === 'new' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                          <span class="mr-1 d-none d-md-block"><i class='bx bx-user-plus'></i></span>
                          <span class="mb-0 font-weight-bold service-type">New</span>
                        </label>
                      </div>
                    </div>

                    <!-- showing error -->
                    <div v-if="frontEndErrors.customerType.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.customerType"
                         :key="index">{{ error }}</p>
                    </div>

                    <div v-if="customerType === 'new'">
                      new
                    </div>
                    <div v-show="customerSearchIsOpen" class="py-2">
                      <div class="row d-flex justify-content-center">
                        <div class="col-md-6 col-12">
                          <div class="form-group position-relative">
                            <label>Search customer</label>
                            <VueMultiselect v-model="selectedCustomer" placeholder="Type to search" label="name"
                                            track-by="name" id="ajax" open-direction="bottom" :options="customerOptions"
                                            :searchable="true" :loading="isLoading" :options-limit="300" :limit="3"
                                            :show-no-results="true" :hide-selected="true"
                                            @search-change="findCustomer"/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="showCustomerInfo">
                  <div>
                    <div class="d-flex align-items-center">
                      <h4 class="font-weight-bold">Customer information</h4>
                      <button class="btn btn-sm btn-info ml-1" @click="stepOne = !stepOne">Change Customer</button>
                    </div>
                    <div>
                      <ul class="list-unstyled">
                        <li class="mb-1">
                          <div class="d-flex align-items-center">
                            <div class="text-capitalize">
                              <span class="text-bold-600 mb-1"
                                    style="text-transform: capitalize;">Name : </span>
                              <span>{{ selectedCustomerInfo.full_name }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="mb-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <span class="text-bold-600 mb-1 text-capitalize"
                                    style="text-transform: capitalize;">Address : </span>
                              <span>{{ selectedCustomerInfo.full_address }}</span>
                              <span style="cursor: pointer;" v-if="can('customer-update')" data-toggle="modal"
                                    data-target="#addressUpdateModal"
                                    title="Edit" class="ml-1 single-edit-btn text-primary"><i
                                  class='bx bx-edit-alt'></i></span>
                              <span v-else title="Edit"><i class='bx bx-edit-alt'></i></span>

                            </div>
                          </div>
                        </li>
                        <li class="mb-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <span class="text-bold-600 mb-1 text-capitalize"
                                    style="text-transform: capitalize;">Email : </span>
                              <span>{{ selectedCustomerInfo.email }}</span>
                            </div>
                          </div>
                        </li>
                        <li class="mb-1">
                          <div class="d-flex align-items-center">
                            <div>
                              <span class="text-bold-600 mb-1 text-capitalize" style="text-transform: capitalize;">Phone Number : </span>
                              <span>{{ selectedCustomerInfo.phone_number }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div v-show="stepTwo">
                  <div class="users-list-filter my-2 rounded">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <input v-model="filterProduct" class="form-control search-product-input-element" type="text"
                               placeholder="Filter a product by name or code" id="filterProduct">
                      </div>
                      <div class="col-12 col-sm-12 col-md-2 col-lg-2 mt-1 mt-md-0">
                        <button @click="searchProduct" class="btn btn-block glow users-list-clear mb-0"
                                :class="searchButtonClicked === true? 'btn-danger': 'btn-primary'">
                          <span>{{ searchButtonClicked === true ? 'Reset' : 'Search' }}</span>
                        </button>
                      </div>
                      <div class="col-12 col-sm-12 col-md-2 col-lg-2 mt-1 mt-md-0">
                        <div class="form-group">
                          <VueMultiselect v-model="selectedPagination" :options="paginationOptions"
                                          :close-on-select="true"
                                          label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-2 col-lg-2 mt-1 mt-md-0">
                        <button @click="loadMoreData" :disabled="this.currentPage >= this.lastPage"
                                class="btn btn-success btn-block glow users-list-clear mb-0">Load more
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="users-list-filter my-2 border rounded">
                    <div class="p-1">
                      <div class="col-md-12 col-12 text-center d-flex justify-content-center">
                        <h4 class="text-bold-600">Product List</h4>
                        <div v-show="searchLoader" class="spinner-border text-primary spinner-border-md ml-1"
                             role="status" aria-hidden="true">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flexbox">
                    <div class="py-1 product-card" v-for="(product, index) in filteredProduct" :key="index">
                      <div class="">
                        <div class="px-2 mb-1 product-image">
                          <img :src="product?.images?.[0]" class="img-fluid"
                               alt="">
                        </div>
                        <div class="text-center">
                          <h6 class="mb-0">{{ product.name.toUpperCase() }}</h6>
                          <p class="small text-secondary mb-0">
                            <span class="font-weight-bold"> Code :</span> {{ product.code }}
                          </p>
                          <p class="small text-secondary mb-0" v-if="product?.productCategory?.name ">
                            <span class="font-weight-bold"> Category :</span> {{ product?.productCategory?.name }}
                          </p>
                          <p class="small text-secondary mb-0">
                            <span class="font-weight-bold">Type :</span> {{ product.type }}
                          </p>
                          <div class="">
                            <button type="button" class="btn btn-sm btn-primary" data-toggle="modal"
                                    data-target="#productConditionsModal" @click="getProductPrices(product.id)">View
                              Items
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div @click="productCartIsOpen=true" class="floatable-cart">
                    <div class="bg-danger d-flex justify-content-center align-items-center"
                         style="height: 40px; width: 60px;border-radius: 5px 0 0 5px;box-shadow: rgb(255 91 92) 0 1px 0, rgb(255 91 92) 0 8px 16px, rgb(17 17 26 / 10%) 0 16px 48px;">
                      <i class='bx bx-cart bx-tada text-white' style="font-size: 28px;"></i>
                    </div>

                  </div>
                  <!-- shopping cart side bar -->
                  <div :class="[productCartIsOpen ? 'product-cart-side-bar-open' :'product-cart-side-bar-close']"
                       :style="{height:'100vh'}">
                    <div class="container h-100">

                      <div class="position-relative " style="height: 100%;">
                        <div class="row justify-content-between align-items-center pl-2 text-bold-700"
                             style="height: 66px;background: #f0f8ff;color:#3F3679">
                          {{ cartList !== undefined ? cartList.length : 0 }} items
                          <button class="btn font-weight-bold font-size-large" @click="productCartIsOpen=false">
                            <i class='bx bx-x ' :style="{fontSize:'30px',color:'#3F3679'}"></i>
                          </button>
                        </div>

                        <div class="c-content" v-if="cartList!== undefined && cartList.length > 0">
                          <div v-for="(cartItem,index) in cartList" :key="index">
                            <div class="w-100 d-flex align-items-center">
                              <div :style="{width:'10%'}">
                                <div class="text-center">
                                  <div @click="increaseQuantity(index)">
                                    <i class='bx bx-plus text-bold-700 cursor-pointer'
                                       :style="{fontWeight:'bold !important'}"></i>
                                  </div>
                                  <div class="text-center">
                                    {{ cartItem.product_price.quantity }}
                                  </div>
                                  <div class="text-center" @click="decreaseQuantity(index)">
                                    <i class='bx bx-minus text-bold-700 cursor-pointer'
                                       :style="{fontWeight:'bold !important'}"></i>
                                  </div>
                                </div>
                              </div>
                              <div :style="{width:'80%'}" class="d-flex">
                                <div class="cart-product-image">
                                  <img class="img-fluid"
                                       :src="cartItem.image"
                                       alt="" srcset="">
                                </div>
                                <div>
                                  <p class="text-dark text-bold-700 mb-0" style="text-transform: uppercase">
                                    {{ cartItem.name }}</p>
                                  <span>Tag: {{ cartItem.product_price.condition_name }}</span><br>
                                  <div>Unit Price:${{ parseFloat(cartItem.product_price.amount / 100).toFixed(2) }}&nbsp;</div>
                                  <div>SubTotal:${{
                                      parseFloat(cartItem.product_price.sub_total / 100).toFixed(2)
                                    }}
                                  </div>

                                </div>
                              </div>
                              <div :style="{width:'10%'}">
                                <div class="d-flex align-items-center justify-content-center"
                                     @click="removeItem(index)">
                                  <i :style="{fontSize:'20px',fontWeight:'bold !important'}"
                                     class='bx bx-x text-bold-700 cursor-pointer text-danger'></i>
                                </div>
                              </div>
                            </div>
                            <hr>
                          </div>
                        </div>
                        <div v-else class="text-capitalize text-center"> No product added in cart</div>

                        <div class=" c-footer position-absolute">
                          <div v-if="total> 0" class="row justify-content-center align-items-center  py-1"
                               style="background: #f0f8ff;color:#3F3679">
                            Total Price : <span class="text-bold-700"> ${{ parseFloat(total / 100).toFixed(2) }}</span>
                          </div>
                          <div class="btn btn-primary d-flex justify-content-center" @click="goOrderCheckout">
                            Place Order
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!--            <div class="" data-toggle="modal" data-target="#productConditionsModal"></div>-->

            <ProductConditionsModal @getSelectedProductCondition="addProductPriceInCart($event)"
                                    :single-product="{...singleProductPricesCondition}"/>
            <AddressUpdateModal :user-id="selectedCustomerInfo.user_id" :model-id="selectedCustomerInfo.customer_id"
                                address-update-for-model-name="customer" modal-name="addressUpdateModal"
                                :business-status="selectedCustomerInfo.type==='Business'"
                                :selected-address-data="{value:selectedCustomerInfo.address_id,name: selectedCustomerInfo.full_address}"
                                @updateModalAddressData="updateModalAddressData"/>
          </section>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

import {
  mapActions,
  mapGetters,
} from "vuex";
import ProductConditionsModal from "@/views/backEnd/ProductConditionsModal";
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";
import Authorization from "@/components/backEnd/mixins/Authorization";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";

export default {
  components: {
    AppLayout,
    ProductConditionsModal,
    AddressUpdateModal,

  },
  mixins: [ShowToastMessage, Loader, Authorization, GlobalMixin],
  props: ['customerId'],
  name: "OrderCreate",
  data() {
    return {
      stepOne: true,
      stepTwo: false,
      productCartIsOpen: false,
      customerSearchIsOpen: false,
      showProductInfo: true,
      showCustomerInfo: false,
      selectedCustomer: '',
      isLoading: false,
      customerID: null,

      getCustomersParams: {
        with_relation: ['user', 'address', 'address.business', 'referredBy'],
        where_has_user_search_query: '',
        status: 1,
      },
      frontEndErrors: {

        customerType: []
      },
      customerType: null,
      step: 1,
      selectedCustomerInfo: {
        user_id: '',
        customer_id: '',
        type: '',
        full_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
        address: '',
        address_id: '',
        full_address: '',
      },
      getProductsParams: {
        with_relation: ['addedBy', 'productCategory', 'media'],
        with_product_prices_state: '',
        // with_product_prices_status: 1,
        where_has_product_price_state_and_status: [],
        paginate: 1,
        pagination: this.$store.getters['appSettings/settingDefaultPagination'].value ?? 20,
        page: 1,

      },
      getProductItemsParams: {
        with_relation: ['product'],
      },
      cartList: [],
      itemsList: [],
      productItemsWithKey: {},
      selectedProductId: null,
      initialProductItemData: {
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
      },
      singleProductPricesCondition: {},
      total: 0,
      mountedCustomer: {},
      filterProduct: '',
      userID: '',
      getSettingsParams: {
        type: ['default', 'product_item'],
        key: ['default_pagination', 'product_item_condition'],
      },
      appointment: {},
      selectedPagination: {
        value: '',
        name: "Default",
      },
      productDataList: [],
      currentPage: 0,
      lastPage: 1,
      searchButtonClicked: false,
      searchLoader: false,
    }
  },
  watch: {
    async selectedCustomer() {
      this.userID = this.selectedCustomer.value;
      this.selectedCustomerInfo = {
        //  customer: this.selectedCustomer.customer,
        customer_id: this.selectedCustomer.customer.id,
        user_id: this.selectedCustomer.customer.user.id,
        full_name: this.userFullName(this.selectedCustomer.customer),
        email: this.selectedCustomer.customer.user.email,
        phone_number: this.selectedCustomer.customer.user.phone_number,
        date_of_birth: this.selectedCustomer.customer.user.date_of_birth,
        address_id: this.selectedCustomer.customer.address.id,
        address: this.selectedCustomer.customer.address,
        full_address: this.userFullAddress(this.selectedCustomer.customer),
        type: this.selectedCustomer.customer.type,
      }
      this.showCustomerInfo = true;
      this.customerSearchIsOpen = false;
      this.customerType = null;
      this.stepTwo = true;
      this.stepOne = false;
      // set product state by productPrices
      this.getProductsParams.with_product_prices_state = this.selectedCustomer.customer.address.state.toLowerCase() ? this.selectedCustomer.customer.address.state.toLowerCase() : '';
      this.getProductsParams.where_has_product_price_state_and_status[0] = this.selectedCustomer.customer.address.state.toLowerCase() ? this.selectedCustomer.customer.address.state.toLowerCase() : '';
      this.getProductsParams.where_has_product_price_state_and_status[1] = 1
      await this.getProductList(this.getProductsParams);
      this.productDataList = this.products;
    },
    customerType() {
      this.customerSearchIsOpen = this.customerType === 'existing';
    },

    async selectedPagination(selectedPagination) {
      this.getProductsParams.paginate = selectedPagination.value === 0 ? 0 : 1;
      this.getProductsParams.pagination = selectedPagination.value;
      this.getProductsParams.page = 1;
      this.currentPage = selectedPagination.value !== 0 ? 0 : 1;
      this.lastPage = 1;
      await this.getProductList(this.getProductsParams);
      this.productDataList = this.products;

    },

  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      products: 'appProducts/products',
      paginateLinks: "appProducts/paginateLinks",
      productItems: 'appProductItems/productItems'
    }),
    customerOptions() {
      let customers = this.$store.getters['appCustomers/customers'].map((customer) => {
        let id = customer.user.id;
        let name = `${customer.user.first_name} ${customer.user.last_name} < ${customer.user?.email ?? ''} , ${customer.user?.phone_number ?? ''}>`;

        return {
          value: id,
          name: name,
          customer: customer,
        };
      });
      return [...customers];
    },

    conditionOptions() {
      return this.$store.getters['appSettings/settingProductItemCondition'].value
    },

    filteredProduct() {
      if (this.filterProduct) {
        return this.productDataList.filter(item => {
          return this.filterProduct
              .toLowerCase()
              .split(" ")
              .every(v => item.name.toLowerCase().includes(v) || item.code.toLowerCase().includes(v));
        });
      } else {
        return this.productDataList;
      }
    },

    paginationOptions() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }, {
        value: 0,
        name: 'All'
      }];
    },
  },
  methods: {
    ...mapActions({
      getCustomers: 'appCustomers/getCustomers',
      getCustomer: 'appCustomers/getCustomer',
      getProducts: 'appProducts/getProducts',
      getProductItems: 'appProductItems/getProductItems',
      postProductItemOnList: 'appProductItems/postProductItemOnList',
      getSettings: 'appSettings/getSettings',

    }),

    async searchProduct() {
      this.getProductsParams.name = this.filterProduct;
      this.getProductsParams.page = 1;
      if (this.searchButtonClicked) {
        this.currentPage = 0
        this.getProductsParams.name = this.filterProduct = '';
        await this.getProductList(this.getProductsParams);
        this.productDataList = this.products;
        this.searchButtonClicked = false;
      }
      if (this.filterProduct.trim() && !this.searchButtonClicked) {
        await this.getProductList(this.getProductsParams);
        this.productDataList = this.products;
        this.searchButtonClicked = true;
      }
    },

    async loadMoreData() {
      this.currentPage = parseInt(this.paginateLinks.current_page);
      this.lastPage = parseInt(this.paginateLinks.last_page);

      if (this.currentPage >= this.lastPage) return;
      this.getProductsParams.page = this.currentPage += 1;
      await this.getProductList(this.getProductsParams);
      this.productDataList = [...this.products, ...this.productDataList];
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    userFullName(customerObj) {
      return `${customerObj.user.first_name} ${customerObj.user.last_name}`;
    },

    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address.street;
      let addressSuburb = customerObj.address.suburb;
      let addressState = customerObj.address.state.toUpperCase();
      let addressPostCode = customerObj.address.post_code;
      let addressCountry = customerObj.address.country;
      return customerObj.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    async updateModalAddressData(updateModalAddressData) {
      //console.log('updateModalAddressData', updateModalAddressData);
      let businessName = updateModalAddressData.addressData?.business?.name ?? '';
      let addressStreet = updateModalAddressData.addressData.street;
      let addressSuburb = updateModalAddressData.addressData.suburb;
      let addressState = updateModalAddressData.addressData.state.toUpperCase();
      let addressPostCode = updateModalAddressData.addressData.postCode;
      let addressCountry = updateModalAddressData.addressData.country;
      this.selectedCustomerInfo.full_address = businessName !== null ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
      this.selectedCustomerInfo.address_id = updateModalAddressData.addressData.id;
      this.selectedCustomerInfo.address = {
        'street': addressStreet,
        'suburb': addressSuburb,
        'state': addressState,
        'post_code': addressPostCode,
        'country': addressCountry,
        'business': {
          'id': updateModalAddressData.addressData?.business?.id,
          'name': updateModalAddressData.addressData?.business?.name,
        }
      };
      this.getProductsParams.with_product_prices_state = updateModalAddressData.addressData.state.toLowerCase();
      this.getProductsParams.where_has_product_price_state_and_status[0] = updateModalAddressData.addressData.state.toLowerCase();
      this.getProductsParams.where_has_product_price_state_and_status[1] = 1;
      await this.getProductList(this.getProductsParams);
      this.productDataList = this.products;
    },

    async findCustomer(query) {
      if (query.length > 2) {
        this.isLoading = true;
        this.getCustomersParams.where_has_user_search_query = query
        setTimeout(async () => {
          await this.getCustomers(this.getCustomersParams)
              .then((response) => {
                this.isLoading = !!(response && response.message && response.status !== 200);
              });

        }, 900);
      }
    },

    async getProductPrices(productId) {
      this.singleProductPricesCondition = {};
      this.singleProductPricesCondition = await this.productDataList.find((product) => product.id === productId);
      /*await this.$nextTick(() => {
        document.querySelector('[data-target="#productConditionsModal"]').click();
      });
      console.log(this.singleProductPricesCondition);*/
    },

    async getProductList(paramObj) {
      this.searchLoader = true;
      await this.getProducts(paramObj)
          .then((response) => {
            this.isLoading = !!(response && response.message && response.status !== 200);
          });
      this.searchLoader = false;
    },

    async getProductItemList(id) {
      this.Loader = true;
      /* let product_item = this.products.find((product) => product.id ===id).productItems;
       this.productItemsWithKey={
         product_id: id,
         product_items:product_item,
       }*/
      this.selectedProductId = id;
      const paramObj = {
        product_id: id,
        with_relation: ['product'],

      }
      await this.getProductItems(paramObj)
          .then((response) => {
            this.Loader = !!(response && response.message && response.status !== 200);
          });
    },

    addProductItemInCart(prodId, prodItemId) {

      // console.log(`prodID :${prodId}`);
      //console.log(`prodItemID :${prodItemId}`);
      this.productCartIsOpen = true;
      let itemIndex = this.productItems.findIndex((item) => item.id === prodItemId);
      let singleProductItemObj = this.productItems[itemIndex];
      let prodIndex = this.cartList.findIndex((singleItem) => singleItem.prodId === prodId)
      // console.log(singleProductItemObj, "product item object to push")
      if (prodIndex === -1) {
        let cartItem = {
          prodId: prodId,
          items: []
        }
        cartItem.items.push(singleProductItemObj)
        this.cartList.push(cartItem);
        this.showToastMessage({
          type: 'success',
          message: 'successfully added in cart'
        });
      } else {
        let prevItem = this.cartList.find((prevItem) => prevItem.prodId === prodId)
        // console.log(prevItem, "existing item")
        let alreadyExistProdItem = prevItem.items.findIndex((singleItem) => singleItem.id === prodItemId)
        // console.log(alreadyExistProdItem, "item exit or not")
        if (alreadyExistProdItem === -1) {
          this.cartList[prodIndex].items.push(singleProductItemObj);
          this.showToastMessage({
            type: 'success',
            message: 'successfully added in cart'
          });
        } else {
          this.showToastMessage({
            type: 'warning',
            message: 'Already added in cart'
          });
        }
      }
    },

    async addProductPriceInCart(obj) {
      //console.log(obj)
      this.productCartIsOpen = true;
      const {productId, productPriceId, amount} = obj
      let selectedProductWithPrices = this.productDataList.find((product) => product.id === productId);
      let selectedPrice = selectedProductWithPrices.productPrices.find((product_price) => product_price.id === productPriceId)
      //find product price ID exist on cart list
      let productPriceIndex = this.cartList.findIndex((singleItem) => singleItem.product_price.id === productPriceId)
      //console.log(selectedProductWithPrices,"finding image");
      if (productPriceIndex === -1) {
        let cartObj = {
          product_id: selectedProductWithPrices.id,
          name: selectedProductWithPrices.name,
          code: selectedProductWithPrices.code,
          image: selectedProductWithPrices?.images?.[0],
          placed_date: this.getAustraliaDateTime('Australia/Sydney'),
          product_price: {
            id: selectedPrice.id,
            condition_name: selectedPrice.condition,
            condition_value: this.conditionOptions.find((option) => option.name.toLowerCase() === selectedPrice.condition.toLowerCase()).value,
            amount: amount * 100, // convert in cent
            sub_total: amount * 100, // convert in cent
            quantity: 1,
          }
        }
        this.cartList.push(cartObj);
        this.showToastMessage({
          type: 'success',
          message: `successfully ${cartObj.name} with ${cartObj.product_price.condition_name} type added in cart`
        });
      } else {
        let existProductInfoOnList = this.cartList[productPriceIndex];
        this.showToastMessage({
          type: 'warning',
          message: `Already ${existProductInfoOnList.name} with ${existProductInfoOnList.product_price.condition_name} type added in cart`
        });
      }
      await this.getTotal()

      // console.log(`${productId}--${productPriceId}--${amount}`);
    },

    increaseQuantity(cartListIndex) {
      this.cartList[cartListIndex].product_price.quantity++;
      this.cartList[cartListIndex].product_price.sub_total = this.cartList[cartListIndex].product_price.amount * this.cartList[cartListIndex].product_price.quantity;
      this.getTotal();
    },

    decreaseQuantity(cartListIndex) {

      if (this.cartList[cartListIndex].product_price.quantity > 1) {
        this.cartList[cartListIndex].product_price.quantity--
        this.cartList[cartListIndex].product_price.sub_total = this.cartList[cartListIndex].product_price.sub_total - this.cartList[cartListIndex].product_price.amount;
      } else {
        this.cartList[cartListIndex].product_price.quantity = 1
      }
      this.getTotal()
      console.log(this.cartList[cartListIndex].product_price.quantity)
      console.log(this.cartList[cartListIndex].product_price.amount)

    },

    /*async addProductItemInfo(data) {
      data.updatedData.product_id = this.selectedProductId;
      await this.postProductItemOnList(data.updatedData).then(async (response) => {
        if (response.status === 201) {
          this.productItems = this.$store.getters['appProductItems/productItems'];
          document.querySelector('[data-dismiss="modal"]').click();
          let toastObj = {
            message: 'product item added successfully',
            type: 'success'
          };
          this.showToastMessage(toastObj);
          const condition = data.conditionOptions;
          const conditionValue = data.updatedData.condition;
          this.initialProductItemData = {
            serial_number: data.updatedData.serial_number,
            model_number: data.updatedData.model_number,
            sku: data.updatedData.sku,
            barcode: data.updatedData.barcode,
            qr_code: data.updatedData.qr_code,
            manufacturing_code: data.updatedData.manufacturing_code,
            purchase_cost: data.updatedData.purchase_cost,
            condition: this.getSelectedOption(condition, conditionValue).name,
          };
        }
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },*/

    removeItem(cartListIndex) {
      this.cartList.splice(cartListIndex, 1);
      this.getTotal()
    },

    removeProductItem(parentIndex, itemIndex) {
      this.cartList[parentIndex].items.splice(itemIndex, 1);
      let itemLength = this.cartList[parentIndex].items.length;

      console.log(parentIndex, itemIndex, itemLength);
      if (itemLength === 0) {
        this.cartList.splice(parentIndex, 1);
      }
    },

    getSelectedOption(haystack, needle) {
      if (Array.isArray(haystack)) {
        let conditionIndex = haystack.findIndex(item => item.value === needle);
        if (conditionIndex !== -1) {
          let status = haystack[conditionIndex];
          return {
            value: status.value,
            name: status.name
          };
        }
      }
      return {};
    },

    async getTotal() {
      this.total = this.cartList.reduce((acc, cur) => acc + Number(cur.product_price.sub_total), 0);
    },

    goOrderCheckout() {
      this.$router.push({
        name: 'appOrderCheckout',
        params: {
          cartList: JSON.stringify(this.cartList),
          selectedCustomer: JSON.stringify(this.selectedCustomerInfo),
          appointment: JSON.stringify(this.appointment),
        },
      });
    },

    async getCustomerInfo(id) {
      this.loader(true);
      const params = {
        id: id,
        params: {with_relation: ['user', 'address', 'address.business', 'referredBy']}
      };
      const response = await this.getCustomer(params);
      if (response && response.status !== 200) {
        this.loader(false);
        this.showToastMessage(response);
      } else {
        this.loader(false);
      }

    },

    async mountedSelectedCustomer(customerId) {
      if (customerId !== undefined) {
        await this.getCustomerInfo(customerId);
        this.mountedCustomer = this.$store.getters['appCustomers/customer'];
        //console.log(this.mountedCustomer);
        this.selectedCustomerInfo = {
          customer_id: customerId,
          user_id: this.mountedCustomer.user.id,
          full_name: this.userFullName(this.mountedCustomer),
          email: this.mountedCustomer.user.email ?? 'Not Provided',
          phone_number: this.mountedCustomer.user.phone_number ?? 'Not Provided',
          date_of_birth: this.mountedCustomer.user.date_of_birth,
          address: this.mountedCustomer.address,
          address_id: this.mountedCustomer.address.id,
          full_address: this.userFullAddress(this.mountedCustomer),
          type: this.mountedCustomer.type,
        }
        this.showCustomerInfo = true;
        this.customerSearchIsOpen = false;
        this.customerType = null;
        this.stepTwo = true;
        this.stepOne = false;
        // set product state by productPrices
        this.getProductsParams.with_product_prices_state = this.selectedCustomerInfo.address.state.toLowerCase() ? this.selectedCustomerInfo.address.state.toLowerCase() : '';
        this.getProductsParams.where_has_product_price_state_and_status[0] = this.selectedCustomerInfo.address.state.toLowerCase() ? this.selectedCustomerInfo.address.state.toLowerCase() : '';
        this.getProductsParams.where_has_product_price_state_and_status[1] = 1
        await this.getProductList(this.getProductsParams);
        this.productDataList = this.products;
      }
    },

    createNewCustomer() {
      console.log(this.$route.name);
      this.$router.push({
        name: 'appCustomerCreate',
        params: {
          routeName: this.$route.name,
          // serviceType: this.serviceType == "remote" ? 1 : 0
        }
      });
    },
  },
  async mounted() {
    await this.loader(true);

    await this.getSettingList(this.getSettingsParams);
    let {cartList, selectedCustomer, appointmentData} = this.$route.params;
    if (appointmentData !== undefined) {
      this.appointment = JSON.parse(appointmentData);
    }

    if (cartList !== undefined && selectedCustomer !== undefined) {
      let cartsData = JSON.parse(cartList);
      let customer = JSON.parse(selectedCustomer);
      await this.mountedSelectedCustomer(customer.customer_id);
      this.cartList = cartsData;
      this.productCartIsOpen = true;
    }

    const mountedCustomerId = this.$props.customerId

    if (mountedCustomerId !== undefined) {
      this.customerType = 'existing';
      await this.mountedSelectedCustomer(mountedCustomerId);
    }
    await this.loader(false);
  }
}
</script>

<style scoped>
@import './order.css';
</style>
